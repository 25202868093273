import Vue from "vue";
import Vuex from "vuex";
import { downloadVideo, sysDownload } from "@/utils/tools";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pathName: "",
    srcList: [],
    nowList: [],
    listLen: 0,
    nowLen: 0,
    actMenu: 0,
    openlive: false,
    SavePath: null,
    mInterval: null,
    statusOptions: [
      {
        value: "1",
        label: "合成中",
      },
      {
        value: "3",
        label: "已合成",
      },
      {
        value: "4",
        label: "合成失败",
      },
    ],
    sortList: [
      {
        value: "dy",
        label: "抖音",
      },
      {
        value: "sph",
        label: "视频号",
      },
      {
        value: "ks",
        label: "快手",
      },
      {
        value: "mt",
        label: "美团",
      },
      {
        value: "pdd",
        label: "拼多多",
      },
      {
        value: "bz",
        label: "哔哩哔哩",
      },
      {
        value: "tk",
        label: "tiktok",
      },
      {
        value: "qita",
        label: "其他",
      },
    ],
  },
  mutations: {
    saveSrcList(state, srcList) {
      state.listLen = srcList.length;
      let arr = [];
      srcList.map((i) => {
        downloadVideo(i.url, i.title + "-" + i.id);
        arr.push(i);
      });
      state.srcList = [...state.srcList, ...arr];
    },

    saveListLen(state, listLen) {
      state.listLen = listLen;
    },
    saveOpenlive(state, openlive) {
      console.log("set", openlive);
      state.openlive = openlive;
    },
    setActMenu(state, actMenu) {
      state.actMenu = actMenu;
    },
    setSavePath(state, SavePath) {
      state.SavePath = SavePath;
    },
    setnowList(state, nowList) {
      state.nowLen = 0;
      state.nowList = [];
      console.log("arrlist", nowList);
      state.nowLen = nowList.length;
      nowList.map((i) => {
        downloadVideo(i.url, i.saveName);
        i.isdownload = true;
        state.nowList.push(i);
      });
    },
    setmInterval(state, mInterval) {
      state.mInterval = mInterval;
    },
  },
});
